<template>
	<div class="main-body">
		<div v-if="infos">
			<img src="//xunliao-1-1257941115.cos.ap-nanjing.myqcloud.com/store_/2021-12-01/1638326836364.png" alt="" class="user_img">
			<div class="user-item">
				<p> <span>萤石AppKey：{{ infos.appkey }}</span> </p>
				<p> <span>萤石Secret：{{ iseye ? infos.secret : '********************************'}}</span> <Icon class="ri-lg" :type="iseye ? 'md-eye' : 'md-eye-off'" @click="iseye = !iseye" /> </p>
			</div>
			<div class="user-btn">
				<Button size="large" style="color: #e6a23c; background: #fdf6ec; border-color: #f5dab1;" type="warning" @click="edit_bind.show = true">换绑萤石云应用</Button>
				<Button size="large" type="error" style="margin-left: 10px; color: #f56c6c; background: #fef0f0; border-color: #fbc4c4;" @click="edit_bind_cancel">取消绑定萤石云</Button>
			</div>
		</div>
		<div v-if="!infos">
			<div class="user-btn">
				<Button size="large" style="color: #63e626; background: #edfde0; border-color: #96f57c;" type="success" @click="edit_bind.show = true">绑定萤石云应用</Button>
			</div>
		</div>
		<Modal v-model="edit_bind.show" title="换绑萤石云应用">
			<Form :label-width="120" label-colon>
				<FormItem label="萤石appkey">
					<Input v-model="edit_bind.form.appkey"></Input>
				</FormItem>
				<FormItem label="萤石secret">
					<Input v-model="edit_bind.form.secret"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="text" @click="edit_bind.show = false">取消</Button>
				<Button type="primary" @click="edit_bind_submit()">保存</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'InternetThings',
		data() {
			return {
				iseye: false ,// 账号密码显示
				infos: {
					appkey: '',
					secret: ''
				},
				edit_bind: {
					show: false,
					form: {
						appkey: '',
						secret: ''
					}
				}
			}
		},
		created() {
			this.init()
		},
		methods: {
			// 初始化 数据
			init(){
				let _this = this 
				this.requestApi('/adm/get_monitoring_app_info').then((res)=>{
					if(res.status){
						_this.infos = res.data
					}else{
						_this.alertErr(res.msg)
					}
				})
				this.infos = null
			},
			// 换绑萤石云应用信息
			edit_bind_submit(){
				let _this = this
				if(!this.edit_bind.form.appkey) return this.alertErr('请输入appkey')
				if(!this.edit_bind.form.secret) return this.alertErr('请输入secret')
				this.requestApi('/adm/set_monitoring_app_info', { type: 0, data: _this.edit_bind.form }).then((res)=>{
					if(res.status){
						_this.alertSucc('成功')
						_this.edit_bind.show = false
						_this.init()
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			edit_bind_cancel(){
				let _this = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定解绑当前萤石云应用信息',
					onOk: () => {
						_this.requestApi('/adm/set_monitoring_app_info', { type: 1 }).then((res)=>{
							if(res.status){
								_this.alertSucc('成功')
								_this.init()
							}else{
								_this.alertErr(res.msg)
							}
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.main-body {
		background-color: #fff;
		padding: 50px 30px;
		text-align: center;
	}

	.main-body .user_img {
		width: 70px;
		height: 70px;
		margin-bottom: 10px;
	}

	.main-body .user-item {
		width: max-content;
		text-align: center;
		margin: 0 auto 60px;
	}

	.main-body .user-item p {
		text-align: left;
		font-size: 16px;
		line-height: 40px;
		display: flex;
		align-items: center;
	}

	.user-item i {
		display: inline-block;
		font-size: 20px;
		margin-right: 6px;
	}

	.user-item-tips {
		cursor: pointer;
		margin-left: 8px;
	}

	.user-item .user_modify {
		font-size: 15px;
		cursor: pointer;
		color: red;
	}

	.user-item p .ri-lg {
		margin-left: 4px;
		font-size: 18px;
	}
</style>
